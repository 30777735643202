import React, { useState, useEffect, useRef } from "react";
import { Canvas, useLoader } from "@react-three/fiber";
import {
  VRCanvas,
  DefaultXRControllers,
  Interactive,
  Controllers,
} from "@react-three/xr";
import { Box, RoundedBox, Sky, Text } from "@react-three/drei";
import axios from "axios";
import { BASE_URL } from "../../utilities/ApiConfig";
import { useNavigate } from "react-router-dom";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";

const options = [
  "Data Analytics",
  "Digital Marketing and E-Commerce",
  "IT Support",
  "Project Management",
  "UX Design",
  "Cybersecurity",
  "General",
];

function PraticeScreen() {
  const [data, setData] = useState([]);
  const [showDialog, setShowDialog] = useState(false);
  const [text, setText] = useState("");
  function Menu() {
    const [selectedOption, setSelectedOption] = useState(null);
    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await axios.get(
            BASE_URL + "api/v1/list/categoryList"
          );
          const fields = response.data.fields;
          // Handle the fields as needed, e.g., setData(fields);
          // console.log(fields);
          setData(fields);
          // setShowDialog(true);
          // setText("output", response.data);
        } catch (error) {
          setShowDialog(true);
          setText(error.message);
          console.error("Error fetching data:", error);
        }
      };
      if (data.length === 0) {
        fetchData();
      }
    }, []);
    const DialogBox = ({ message }) => {
      const dialogRef = useRef();
      const [hovered, setHovered] = useState(false);
      return (
        <group position={[-3, 1.6, -3]} ref={dialogRef}>
          <RoundedBox args={[1, 0.5, 0.02]} radius={0.05} smoothness={4}>
            <meshStandardMaterial color="purple" />
          </RoundedBox>
          <Text
            fontSize={0.08}
            position={[0, 0.1, 0.03]}
            color="white"
            anchorX="center"
            anchorY="middle"
          >
            {message}
          </Text>
        </group>
      );
    };
    function MenuButton({ label, position, onClick }) {
      const [hovered, setHovered] = useState(false);
      const buttonRef = useRef();
      return (
        <Interactive
          onClick={onClick}
          onSelect={onClick}
          onHover={() => setHovered(true)}
          onBlur={() => setHovered(false)}
          onSelectStart={onClick}
          onSelectEnd={onClick}
        >
          <group position={position} ref={buttonRef}>
            <RoundedBox args={[0.8, 0.18, 0.05]} radius={0.05} smoothness={4}>
              <meshStandardMaterial color={hovered ? "white" : "green"} />
              <Text
                fontSize={0.05}
                position={[0, 0, 0.05]}
                color={hovered ? "black" : "white"}
                anchorX="center"
                anchorY="middle"
              >
                {label}
              </Text>
            </RoundedBox>
          </group>
        </Interactive>
      );
    }

    const navigate = useNavigate();

    const NavigationButton = (navigateTo) => {
      navigate(navigateTo);
    };
    return (
      <group position={[0, 1.5, -1]}>
        <RoundedBox
          args={[2, 2.5, -0.2]}
          position={[0, 1.5, -3]}
          radius={0.05}
          smoothness={4}
        >
          <Text
            fontSize={0.09}
            color="#000"
            anchorX="center"
            anchorY="middle"
            position={[0, 1.2, 0]}
          >
            What field do you want to practice for?
          </Text>
          {data &&
            data.length > 0 &&
            data.map((option, index) => (
              <MenuButton
                key={option}
                label={option}
                position={[0, 0.6 - index * 0.28, 0]}
                onClick={() => NavigationButton("/InterviewScreen")}
              />
            ))}
        </RoundedBox>
        {/* {showDialog && <DialogBox message={text} />} */}
      </group>
    );
  }
  const Model = (props) => {
    const gltf = useLoader(GLTFLoader, "model/sunroof.glb");

    return <primitive object={gltf.scene} {...props} />;
  };
  return (
    <>
      <Sky sunPosition={[0, 1, 0]} />
      <ambientLight intensity={0.5} />
      <pointLight position={[10, 10, 10]} />
      <Controllers />
      <Model position={[0, 2, -3]} />
      <group position={[0, -1.5, 1]}>
        <Menu />
      </group>
    </>
  );
}
export default PraticeScreen;
