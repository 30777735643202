import React, { Suspense, useEffect, useMemo, useRef, useState } from "react";
import { VRCanvas, Controllers, Interactive, Hands } from "@react-three/xr";
import { RoundedBox, Sky, Text } from "@react-three/drei";
import { ResonanceAudio } from "resonance-audio";
import axios from "axios";
import { BASE_URL } from "../utilities/ApiConfig";
import { useLoader } from "@react-three/fiber";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";

function InterviewScreen() {
  const [isRecording, setIsRecording] = useState(false);
  const [audioBuffer, setAudioBuffer] = useState(null);
  const audioContextRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const resonanceAudioSceneRef = useRef(null);
  const [showDialog, setShowDialog] = useState(false);
  const [text, setText] = useState("");
  const [RedoButton, setRedoButton] = useState(false);
  const [doneButton, setDoneButton] = useState(false);
  const [audioURL, setAudioURL] = useState(null);
  const [responseText, setResponseText] = useState("");

  const handleStartRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

      if (!audioContextRef.current) {
        audioContextRef.current = new (window.AudioContext ||
          window.webkitAudioContext)();
      }

      if (audioContextRef.current.state === "suspended") {
        await audioContextRef.current.resume();
      }

      if (!resonanceAudioSceneRef.current) {
        resonanceAudioSceneRef.current = new ResonanceAudio(
          audioContextRef.current
        );
        // Don't connect to the destination while recording to avoid echo
      }

      const micSource = audioContextRef.current.createMediaStreamSource(stream);
      const resonanceSource = resonanceAudioSceneRef.current.createSource();
      micSource.connect(resonanceSource.input);

      mediaRecorderRef.current = new MediaRecorder(stream);
      audioChunksRef.current = [];

      mediaRecorderRef.current.ondataavailable = (event) => {
        audioChunksRef.current.push(event.data);
      };

      mediaRecorderRef.current.start();
      setIsRecording(true);
      setDoneButton(true);
    } catch (error) {
      setShowDialog(true);
      setText(error.message);
      console.error(
        "Error accessing microphone or setting up Resonance Audio:",
        error
      );
    }
  };

  const handleStopRecording = async () => {
    setDoneButton(false);
    if (mediaRecorderRef.current) {
      // Set up the onstop handler before stopping the recording
      mediaRecorderRef.current.onstop = async () => {
        const audioBlob = new Blob(audioChunksRef.current, {
          type: "audio/wav",
        });

        const audioURL = URL.createObjectURL(audioBlob);
        setAudioURL(audioURL);

        const formData = new FormData();
        formData.append("file", audioBlob, "recording.wav");

        try {
          const response = await axios.post(
            BASE_URL + "api/v1/list/speechtotext",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
          setRedoButton(true);
          setShowDialog(true);
          setText(response.data.transcript);

          const transcriptText = response.data.transcript;

          let body = {
            text: transcriptText,
          };

          try {
            const textResponse = await axios.post(
              BASE_URL + "api/v1/list/texttospeech",
              body,
              {
                responseType: "blob", // Set responseType to blob to handle binary data
              }
            );

            const audioBlob = textResponse.data;
            const audioURL = URL.createObjectURL(audioBlob);
            setResponseText(transcriptText);
            // Play the audio using the Audio element
            const audioElement = new Audio(audioURL);
            audioElement.play();
          } catch (textError) {
            console.error("Error sending text data:", textError.message);
            setShowDialog(true);
            setText("Error sending text data: " + textError.message);
          }
        } catch (error) {
          console.error("Error sending audio file to server:", error.message);
          setShowDialog(true);
          setText(error.message);
        }
      };

      // Stop recording
      mediaRecorderRef.current.stop();
    }
  };

  const handlePlayAudio = () => {
    if (audioBuffer) {
      const source = audioContextRef.current.createBufferSource();
      source.buffer = audioBuffer;
      source.connect(audioContextRef.current.destination);
      source.start(0);
    }
  };

  const handleRedo = () => {
    setResponseText("");
    setRedoButton(false);
    setIsRecording(false);
  };

  ///
  const Button = ({ position, text, onSelect }) => {
    const [hovered, setHovered] = useState(false);

    return (
      <Interactive
        onClick={onSelect}
        onSelect={onSelect}
        onHover={() => setHovered(true)}
        onBlur={() => setHovered(false)}
        // onSelectStart={onSelect}
        // onSelectEnd={onSelect}
      >
        <RoundedBox
          args={[0.4, 0.2, 0.1]}
          position={position}
          radius={0.05}
          smoothness={4}
        >
          <meshStandardMaterial color={hovered ? "white" : "green"} />
          <Text
            fontSize={0.05}
            position={[0, 0, 0.1]}
            color={hovered ? "black" : "white"}
            anchorX="center"
            anchorY="middle"
          >
            {text}
          </Text>
        </RoundedBox>
      </Interactive>
    );
  };

  const DialogBox = ({ message }) => {
    const dialogRef = useRef();
    const [hovered, setHovered] = useState(false);
    return (
      <group position={[-3, 1.6, -3]} ref={dialogRef}>
        <RoundedBox args={[1, 0.5, 0.02]} radius={0.05} smoothness={4}>
          <meshStandardMaterial color="purple" />
        </RoundedBox>
        <Text
          fontSize={0.08}
          position={[0, 0.1, 0.03]}
          color="white"
          anchorX="center"
          anchorY="middle"
        >
          {message}
        </Text>
      </group>
    );
  };

  const handleSaveAudio = () => {
    if (!audioURL) return;

    // Create a link element to trigger the download
    const link = document.createElement("a");
    link.href = audioURL;
    link.download = "checkingaudio.wav";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const boxHeight = useMemo(() => {
    const minHeight = 0.6; // minimum height of the box
    const lineHeight = 0.1; // height per line of text
    const charsPerLine = 30; // approximate number of characters per line

    // Calculate the number of lines needed
    const numberOfLines = Math.ceil(responseText.length / charsPerLine);

    // Calculate the height based on the number of lines
    return minHeight + numberOfLines * lineHeight;
  }, [responseText]);
  const buttonYPosition = useMemo(() => {
    return 0.65 - boxHeight / 2 - 0.3; // Adjust this value as needed for spacing
  }, [boxHeight]);
  const Model = (props) => {
    const gltf = useLoader(GLTFLoader, "model/sunroof.glb");

    return <primitive object={gltf.scene} {...props} />;
  };
  return (
    <>
      <ambientLight intensity={0.5} />
      <pointLight position={[10, 10, 10]} />
      <Sky />
      <Controllers />
      <Model position={[0, 2, -3]} />
      <group position={[0, 0, 1]}>
        <RoundedBox
          args={[1.5, boxHeight, 0.1]}
          position={[0, 1.5, -3]}
          radius={0.05}
          smoothness={4}
        >
          <meshStandardMaterial color="#FFFFFF" />
          <Text fontSize={0.05} position={[0, 0, 0.2]} color="black">
            What are you looking for in your next job?
          </Text>
          {responseText != "" && (
            <Text
              maxWidth={1.3} // Set maxWidth to control text wrapping
              // lineHeight={1.2} // Optional: adjust the line height
              fontSize={0.05}
              position={[0, -0.2, 0.2]}
              color="black"
            >
              Your Answer :{responseText}
            </Text>
          )}
        </RoundedBox>
        <group position={[0, buttonYPosition, 0]}>
          {!isRecording && (
            <Button
              position={[-0.5, 0.95, -3]}
              text={"Answer"}
              onSelect={handleStartRecording}
            />
          )}
          {doneButton && isRecording && !RedoButton && (
            <Button
              // position={[0, 0.95, -3]}
              position={[-0.5, 0.95, -3]}
              text={"Done"}
              onSelect={handleStopRecording}
            />
          )}
          {audioBuffer && (
            <Button
              position={[0.5, 0.95, -3]}
              text={"Play"}
              onSelect={handlePlayAudio}
            />
          )}

          {/* {audioURL && (
            <Button
              position={[0.5, 0.95, -3]}
              text="Save Recording"
              onSelect={handleSaveAudio}
            />
          )} */}
          {RedoButton && (
            <Button
              position={[0.5, 0.95, -3]}
              text="Redo"
              onSelect={handleRedo}
            />
          )}
        </group>

        {/* {showDialog && <DialogBox message={text} />} */}
      </group>
    </>
  );
}

export default InterviewScreen;
