import React, { useEffect, useRef, useState } from "react";
import { XR, Hands, Interactive, Controllers } from "@react-three/xr";
import { Suspense } from "react";
import { PositionalAudio, RoundedBox, Sky, Text } from "@react-three/drei";
import axios from "axios";
import { useLoader, useThree } from "@react-three/fiber";
import { Audio, AudioListener, AudioLoader } from "three";
import useTextFromUrl from "../common/Filetotext";

function AudioPlayer({ url }) {
  const sound = useRef();
  const handlePlay = () => {
    if (sound.current) {
      sound.current.play();
    }
  };

  const handlePause = () => {
    if (sound.current) {
      sound.current.pause();
    }
  };
  return (
    <mesh>
      <PositionalAudio ref={sound} url={url} />
      <VRButtons position={[0, 0, 0]} onClick={handlePlay} label="play" />
      <VRButtons position={[0, 0.3, 0]} onClick={handlePause} label="Pause" />
    </mesh>
  );
}
function VRButtons({ position, onClick, label }) {
  const [hovered, setHovered] = useState(false);
  const buttonRef = useRef();

  return (
    <Interactive
      onHover={() => setHovered(true)}
      onBlur={() => setHovered(false)}
      onSelect={onClick}
    >
      <group position={position} ref={buttonRef}>
        <RoundedBox args={[0.65, 0.15, 0.05]} radius={0.05} smoothness={4}>
          <meshStandardMaterial color={hovered ? "white" : "green"} />
          <Text
            fontSize={0.05}
            position={[0, 0, 0.03]}
            color={hovered ? "black" : "white"}
            anchorX="center"
            anchorY="middle"
          >
            {label}
          </Text>
        </RoundedBox>
      </group>
    </Interactive>
  );
}

function VRScene({ onSpeak, onPlay, audioUrl }) {
  return (
    <>
      <Suspense fallback={null} />
      <Sky sunPosition={[0, 1, 0]} />
      <ambientLight intensity={0.5} />
      <pointLight position={[10, 10, 10]} />
      <Hands />
      <Controllers />

      <group position={[0, 1.6, -2]}>
        <VRButtons
          position={[-0.8, 0.3, 0]}
          onClick={onSpeak}
          label="Generate Speech"
        />
        {audioUrl && (
          <VRButtons
            position={[0.8, 0.3, 0]}
            onClick={onPlay}
            label="Play Speech"
          />
        )}
      </group>
    </>
  );
}

function DemoTexttoSpeech() {
  const [text, setText] = useState("Hello, this is a VR environment!");
  const [audioUrl, setAudioUrl] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const [response, setResponse] = useState("");
  const [errormsg, seterrormsg] = useState("");

  const [responseurl, setresponseurl] = useState(false);
  const audioContext = new (window.AudioContext || window.webkitAudioContext)();
  const [playAudiobtn, setPlayaudio] = useState(false);
  const url =
    "https://cors-anywhere.herokuapp.com/https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3";
  let testUrl =
    "https://cors-anywhere.herokuapp.com/https://storage.googleapis.com/cloudlabs-tts.appspot.com/audio/audio-9234eb1bcaf9e78b3e5a9a22647613b6.mp3";

  const speakText = async () => {
    // setAudioUrl(testUrl);
    //
    // setresponseurl(url);
    const data = new FormData();
    data.append("voice_code", "en-US-1");
    data.append("text", response);
    data.append("speed", "1.00");
    data.append("pitch", "1.00");
    data.append("output_type", "audio_url");
    const options = {
      method: "POST",
      headers: {
        "x-rapidapi-key": "91b4ba75e6msh5f9d926cc9bf148p14de6cjsnc2d207c6ec0a", //
        "x-rapidapi-host": "cloudlabs-text-to-speech.p.rapidapi.com",
      },
      url: "https://cloudlabs-text-to-speech.p.rapidapi.com/synthesize",
      data: data,
    };
    try {
      const response = await axios.request(options);
      //   seturl(response.data?.result?.audio_url);
      // const blob = response.data?.result?.audio_url;
      // const url = URL.createObjectURL(blob);
      setAudioUrl(
        `https://cors-anywhere.herokuapp.com/${response.data?.result?.audio_url}`
      );
    } catch (error) {
      setShowDialog(true);
      seterrormsg(error.message);
      console.error(error);
      alert("Error: ", error);
    }
  };

  //   const handleClick = (textUrl) => {
  //     getText(textUrl)
  //       .then((text) => {
  //         setResponse(text);
  //       })
  //       .catch((error) => {
  //         console.error("Error:", error);
  //       });
  //   };

  const handleClick = (textUrl) => {
    fetch(textUrl)
      .then((response) => response.text())
      .then((data) => {
        setResponse(data);
      })
      .catch((error) => console.error("Error fetching text from URL:", error));
  };

  const DialogBox = ({ message }) => {
    const dialogRef = useRef();
    const [hovered, setHovered] = useState(false);
    return (
      <group position={[-1, 1.6, -0.8]} ref={dialogRef}>
        <RoundedBox args={[1, 0.5, 0.02]} radius={0.05} smoothness={4}>
          <meshStandardMaterial color="purple" />
        </RoundedBox>
        <Text
          fontSize={0.08}
          position={[0, 0.1, 0.03]}
          color="white"
          anchorX="center"
          anchorY="middle"
        >
          {message}
        </Text>
      </group>
    );
  };

  const playAudio = () => {
    setPlayaudio(true);
  };

  const VRTextDisplay = ({ response }) => {
    return (
      <Text
        position={[0, 0, -5]} // Position in VR space
        color="black" // Text color
        fontSize={0.5} // Text size
        maxWidth={5} // Maximum width of text before wrapping
        lineHeight={1.2} // Line height
        textAlign={"center"} // Align text to center
        anchorX="center" // Anchor text horizontally
        anchorY="middle" // Anchor text vertically
      >
        {response}
      </Text>
    );
  };
  const textUrl =
    "https://cors-anywhere.herokuapp.com/https://example-files.online-convert.com/document/txt/example.txt";

  const uploadtext =
    "https://cors-anywhere.herokuapp.com/https://srinitextfiles.s3.amazonaws.com/textfile.txt";

  //   function getText(textUrl) {
  //     return new Promise((resolve, reject) => {
  //       var request = new XMLHttpRequest();
  //       request.open("GET", textUrl, true);
  //       request.onreadystatechange = function () {
  //         if (request.readyState === 4) {
  //           if (request.status === 200) {
  //             var type = request.getResponseHeader("Content-Type");
  //             if (type.indexOf("text") !== -1) {
  //               resolve(request.responseText);
  //             } else {
  //               reject("Invalid content type");
  //             }
  //           } else {
  //             reject("Error fetching the text");
  //           }
  //         }
  //       };
  //       request.send(null);
  //     });
  //   }

  return (
    <>
      <>
        <Suspense fallback={null} />
        <Sky sunPosition={[0, 1, 0]} />
        <ambientLight intensity={0.5} />
        <pointLight position={[10, 10, 10]} />
        <Hands />
        <Controllers />
        <group position={[0, 1.6, -2]}>
          {response != "" && (
            <VRButtons
              position={[0, 0.6, 0]}
              onClick={speakText}
              label="Text to Speech"
            />
          )}
          <VRButtons
            position={[0, 0.9, 0]}
            onClick={() => {
              handleClick(textUrl);
            }}
            label="getText"
          />

          {/* {response != "" && <VRTextDisplay response={response} />} */}
          {audioUrl && <AudioPlayer url={audioUrl} />}
        </group>
        {/* {showDialog != "" && <DialogBox message={errormsg} />} */}
      </>
    </>
  );
}

export default DemoTexttoSpeech;
