// import React, { useEffect, useRef, useState } from "react";
// import { Canvas, useFrame } from "@react-three/fiber";
// import { XR, VRButton, Hands, Interactive, Controllers } from "@react-three/xr";
// import { Suspense } from "react";
// import { ResonanceAudio } from "resonance-audio";
// import * as THREE from "three";
// import { RoundedBox, Sky, Text, useTexture } from "@react-three/drei";
// import { AssemblyAI } from "assemblyai";

// const client = new AssemblyAI({
//   apiKey: "7c39a40bdfad43dc916bb67844c122ff",
// });

// function AudioCapture({ isRecording, setAudioURL, onAudioRecorded }) {
//   const audioContextRef = useRef(null);
//   const resonanceAudioSceneRef = useRef(null);
//   const micSourceRef = useRef(null);
//   const resonanceSourceRef = useRef(null);
//   const mediaRecorderRef = useRef(null);
//   const audioChunksRef = useRef([]);

//   useEffect(() => {
//     // Initialize Audio Context and Resonance Audio Scene
//     audioContextRef.current = new window.AudioContext();
//     resonanceAudioSceneRef.current = new ResonanceAudio(
//       audioContextRef.current
//     );
//     resonanceAudioSceneRef.current.output.connect(
//       audioContextRef.current.destination
//     );

//     // Get user media (microphone)
//     navigator.mediaDevices
//       .getUserMedia({ audio: true })
//       .then((stream) => {
//         micSourceRef.current =
//           audioContextRef.current.createMediaStreamSource(stream);
//         resonanceSourceRef.current =
//           resonanceAudioSceneRef.current.createSource();
//         micSourceRef.current.connect(resonanceSourceRef.current.input);

//         // Initialize MediaRecorder
//         mediaRecorderRef.current = new MediaRecorder(stream);
//         mediaRecorderRef.current.ondataavailable = (e) => {
//           audioChunksRef.current.push(e.data);
//         };
//         mediaRecorderRef.current.onstop = async () => {
//           const audioBlob = new Blob(audioChunksRef.current, {
//             type: "audio/wav",
//           });
//           const audioURL = URL.createObjectURL(audioBlob);
//           setAudioURL(audioURL);
//           audioChunksRef.current = [];
//           onAudioRecorded(audioBlob);
//         };
//       })
//       .catch((err) => {
//         console.error("Error accessing microphone:", err);
//       });

//     return () => {
//       if (micSourceRef.current) {
//         micSourceRef.current.disconnect();
//       }
//       if (resonanceSourceRef.current) {
//         resonanceSourceRef.current.input.disconnect();
//       }
//     };
//   }, [setAudioURL, onAudioRecorded]);

//   useEffect(() => {
//     if (mediaRecorderRef.current) {
//       if (isRecording) {
//         mediaRecorderRef.current.start();
//       } else {
//         mediaRecorderRef.current.stop();
//       }
//     }
//   }, [isRecording]);

//   return null;
// }

// function VRButtons({ position, onClick, label }) {
//   const [hovered, setHovered] = useState(false);
//   const buttonRef = useRef();
//   return (
//     <Interactive
//       onHover={() => setHovered(true)}
//       onBlur={() => setHovered(false)}
//       onSelect={onClick}
//       onSelectStart={onClick}
//       onSelectEnd={onClick}
//     >
//       <group position={position} ref={buttonRef}>
//         <RoundedBox args={[0.65, 0.15, 0.05]} radius={0.05} smoothness={4}>
//           <meshStandardMaterial color={hovered ? "white" : "green"} />
//           <Text
//             fontSize={0.05}
//             position={[0, 0, 0.03]}
//             color={hovered ? "black" : "white"}
//             anchorX="center"
//             anchorY="middle"
//           >
//             {label}
//           </Text>
//         </RoundedBox>
//       </group>
//     </Interactive>
//   );
// }

// function VRScene({
//   isRecording,
//   setIsRecording,
//   audioURL,
//   setAudioURL,
//   onSendAudio,
// }) {
//   const handleAudioRecorded = async (audioBlob) => {
//     const formData = new FormData();
//     formData.append("file", audioBlob, "recording.wav");
//     // const file = new File([audioBlob], "recording.wav", { type: "audio/wav" });
//     // saveFileToDevice(file);
//     try {
//       const response = await client.TranscriptService.create({
//         audio_url: URL.createObjectURL(audioBlob),
//       });
//       console.log("Transcript:", response.text);
//       alert("Transcript", response.text);
//     } catch (error) {
//       console.error("Error transcribing audio:", error);
//     }
//   };

//   function saveFileToDevice(file) {
//     const a = document.createElement("a");
//     const url = URL.createObjectURL(file);
//     a.href = url;
//     a.download = file.name;
//     document.body.appendChild(a);
//     a.click();
//     setTimeout(() => {
//       document.body.removeChild(a);
//       window.URL.revokeObjectURL(url);
//     }, 0);
//   }

//   const handleSaveRecording = () => {
//     if (audioURL) {
//       // Convert the audio URL to a Blob
//       fetch(audioURL)
//         .then((response) => response.blob())
//         .then((blob) => {
//           const file = new File([blob], "recording.wav", { type: "audio/wav" });
//           saveFileToDevice(file);
//         })
//         .catch((error) => {
//           console.error("Error saving recording:", error);
//           alert("Error saving recording");
//         });
//     }
//   };
//   return (
//     <>
//       <Suspense fallback={null}></Suspense>
//       <Sky sunPosition={[0, 1, 0]} />
//       <ambientLight intensity={0.5} />
//       <pointLight position={[10, 10, 10]} />
//       <Hands />
//       <Controllers />
//       <AudioCapture
//         isRecording={isRecording}
//         setAudioURL={setAudioURL}
//         onAudioRecorded={handleAudioRecorded}
//       />
//       <group position={[0, 1.6, -2]}>
//         <VRButtons
//           position={[0, 0.3, 0]}
//           onClick={() => setIsRecording(true)}
//           label="Start Recording"
//         />
//         <VRButtons
//           position={[0, 0.1, 0]}
//           onClick={() => setIsRecording(false)}
//           label="Stop Recording"
//         />
//         {audioURL && (
//           <VRButtons
//             position={[0, -0.1, 0]}
//             onClick={() => new Audio(audioURL).play()}
//             label="Play Recording"
//           />
//         )}
//         {audioURL && (
//           <VRButtons
//             position={[0, -0.4, 0]}
//             onClick={() => setAudioURL(null)}
//             label="Delete Recording"
//           />
//         )}
//         {audioURL && (
//           <VRButtons
//             position={[0, -0.7, 0]}
//             onClick={onSendAudio}
//             label="Send Recording"
//           />
//         )}
//         {audioURL && (
//           <VRButtons
//             position={[0, -0.7, 0]}
//             onClick={handleSaveRecording}
//             label="Save Recording"
//           />
//         )}
//       </group>
//     </>
//   );
// }

// const DialogBox = ({ message, onClose }) => {
//   const dialogRef = useRef();
//   const [hovered, setHovered] = useState(false);

//   return (
//     <group position={[-1, 1.6, -0.8]} ref={dialogRef}>
//       <RoundedBox args={[1, 0.5, 0.02]} radius={0.05} smoothness={4}>
//         <meshStandardMaterial color="purple" />
//       </RoundedBox>
//       <Text
//         fontSize={0.08}
//         position={[0, 0.1, 0.03]}
//         color="white"
//         anchorX="center"
//         anchorY="middle"
//       >
//         {message}
//       </Text>
//       <Interactive
//         onSelect={onClose}
//         onHover={() => setHovered(true)}
//         onBlur={() => setHovered(false)}
//       >
//         <Button
//           label="Close"
//           icon="icons/room2.png"
//           position={[0, -0.1, 0]}
//           onClick={() => onClose}
//         />
//       </Interactive>

//       <RoundedBox
//         args={[0.8, 0.2, 0.02]}
//         radius={0.05}
//         smoothness={4}
//         position={[0, -0.1, 0]}
//       >
//         <meshStandardMaterial color={hovered ? "white" : "green"} />
//       </RoundedBox>

//       <Text
//         fontSize={0.04}
//         position={[0, -0.1, 0.03]}
//         color={hovered ? "black" : "white"}
//         anchorX="center"
//         anchorY="middle"
//       >
//         Close
//       </Text>
//     </group>
//   );
// };
// const Button = ({ label, icon, position, onClick }) => {
//   let placeholderTexture = "icons/vravatar.webp";
//   const iconTexture = useTexture(icon ? icon : placeholderTexture);

//   const [hovered, setHovered] = useState(false);
//   const buttonRef = useRef();

//   return (
//     <Interactive
//       onHover={() => setHovered(true)}
//       onBlur={() => setHovered(false)}
//       onSelect={onClick}
//       onSelectStart={onClick}
//       onSelectEnd={onClick}
//     >
//       <group
//         position={position}
//         ref={buttonRef}
//         anchorX="center"
//         anchorY="middle"
//       >
//         <RoundedBox
//           args={[0.65, 0.15, 0.05]}
//           // position={[-0.6, 1.5, 0.1]}
//           radius={0.05}
//           smoothness={4}
//         >
//           <meshStandardMaterial color={hovered ? "white" : "green"} />
//           <Text
//             fontSize={0.05}
//             position={[0, 0, 0.03]}
//             color={hovered ? "black" : "white"}
//             anchorX="center"
//             anchorY="middle"
//           >
//             {label}
//           </Text>
//         </RoundedBox>

//         {icon && (
//           <mesh position={[-0.2, 0, 0.03]} scale={[0.09, 0.09, 0.09]}>
//             <planeBufferGeometry args={[0.7, 0.4]} />
//             <meshBasicMaterial map={iconTexture} />
//           </mesh>
//         )}
//       </group>
//     </Interactive>
//   );
// };
// function SpeechRecording() {
//   const [isRecording, setIsRecording] = useState(false);
//   const [audioURL, setAudioURL] = useState(null);
//   const [responseText, setresponseText] = useState(null);

//   const handleSendAudio = async () => {
//     if (audioURL) {
//       try {
//         const response = await client.transcripts.create({
//           audio_url: audioURL,
//         });
//         console.log("Transcript:", response.text);
//         alert("Transcript:", response.text);
//         setresponseText(response.text);
//       } catch (error) {
//         console.error("Error transcribing audio:", error);
//       }
//     }
//   };

//   return (
//     <>
//       <VRScene
//         isRecording={isRecording}
//         setIsRecording={setIsRecording}
//         audioURL={audioURL}
//         setAudioURL={setAudioURL}
//         onSendAudio={handleSendAudio}
//       />
//       {responseText && (
//         <DialogBox message={responseText} onClose={setresponseText(null)} />
//       )}
//     </>
//   );
// }

// export default SpeechRecording;

import React, { useEffect, useRef, useState } from "react";
import { Canvas, useFrame } from "@react-three/fiber";
import { XR, VRButton, Hands, Interactive, Controllers } from "@react-three/xr";
import { Suspense } from "react";
import { ResonanceAudio } from "resonance-audio";
import * as THREE from "three";
import { RoundedBox, Sky, Text, useTexture } from "@react-three/drei";
import { AssemblyAI } from "assemblyai";

const client = new AssemblyAI({
  apiKey: "7c39a40bdfad43dc916bb67844c122ff",
});

// function AudioCapture({ isRecording, setAudioURL, onAudioRecorded }) {
//   const audioContextRef = useRef(null);
//   const resonanceAudioSceneRef = useRef(null);
//   const micSourceRef = useRef(null);
//   const resonanceSourceRef = useRef(null);
//   const mediaRecorderRef = useRef(null);
//   const audioChunksRef = useRef([]);

//   useEffect(() => {
//     // Initialize Audio Context and Resonance Audio Scene
//     audioContextRef.current = new window.AudioContext();
//     resonanceAudioSceneRef.current = new ResonanceAudio(
//       audioContextRef.current
//     );
//     resonanceAudioSceneRef.current.output.connect(
//       audioContextRef.current.destination
//     );

//     // Get user media (microphone)
//     navigator.mediaDevices
//       .getUserMedia({ audio: true })
//       .then((stream) => {
//         micSourceRef.current =
//           audioContextRef.current.createMediaStreamSource(stream);
//         resonanceSourceRef.current =
//           resonanceAudioSceneRef.current.createSource();
//         micSourceRef.current.connect(resonanceSourceRef.current.input);

//         // Initialize MediaRecorder
//         mediaRecorderRef.current = new MediaRecorder(stream);
//         mediaRecorderRef.current.ondataavailable = (e) => {
//           audioChunksRef.current.push(e.data);
//         };
//         mediaRecorderRef.current.onstop = async () => {
//           const audioBlob = new Blob(audioChunksRef.current, {
//             type: "audio/wav",
//           });
//           const audioURL = URL.createObjectURL(audioBlob);
//           setAudioURL(audioURL);
//           audioChunksRef.current = [];
//           onAudioRecorded(audioBlob);
//         };
//       })
//       .catch((err) => {
//         console.error("Error accessing microphone:", err);
//       });

//     return () => {
//       if (micSourceRef.current) {
//         micSourceRef.current.disconnect();
//       }
//       if (resonanceSourceRef.current) {
//         resonanceSourceRef.current.input.disconnect();
//       }
//     };
//   }, [setAudioURL, onAudioRecorded]);

//   useEffect(() => {
//     if (mediaRecorderRef.current) {
//       if (isRecording) {
//         mediaRecorderRef.current.start();
//       } else if (mediaRecorderRef.current.state !== "inactive") {
//         mediaRecorderRef.current.stop();
//       }
//     }
//   }, [isRecording]);

//   return null;
// }

function AudioCapture({ isRecording, setAudioURL }) {
  const audioContextRef = useRef(null);
  const resonanceAudioSceneRef = useRef(null);
  const micSourceRef = useRef(null);
  const resonanceSourceRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);

  useEffect(() => {
    // Initialize Audio Context and Resonance Audio Scene
    audioContextRef.current = new window.AudioContext();
    resonanceAudioSceneRef.current = new ResonanceAudio(
      audioContextRef.current
    );
    resonanceAudioSceneRef.current.output.connect(
      audioContextRef.current.destination
    );

    // Get user media (microphone)
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        micSourceRef.current =
          audioContextRef.current.createMediaStreamSource(stream);
        resonanceSourceRef.current =
          resonanceAudioSceneRef.current.createSource();
        micSourceRef.current.connect(resonanceSourceRef.current.input);

        // Initialize MediaRecorder
        mediaRecorderRef.current = new MediaRecorder(stream);
        mediaRecorderRef.current.ondataavailable = (e) => {
          audioChunksRef.current.push(e.data);
        };
        mediaRecorderRef.current.onstop = () => {
          const audioBlob = new Blob(audioChunksRef.current, {
            type: "audio/wav",
          });
          const audioURL = URL.createObjectURL(audioBlob);
          setAudioURL(audioURL);
          audioChunksRef.current = [];
        };
      })
      .catch((err) => {
        console.error("Error accessing microphone:", err);
      });

    return () => {
      if (micSourceRef.current) {
        micSourceRef.current.disconnect();
      }
      if (resonanceSourceRef.current) {
        resonanceSourceRef.current.input.disconnect();
      }
    };
  }, [setAudioURL]);

  useEffect(() => {
    if (mediaRecorderRef.current) {
      if (isRecording) {
        mediaRecorderRef.current.start();
      } else {
        mediaRecorderRef.current.stop();
      }
    }
  }, [isRecording]);

  return null;
}
function VRButtons({ position, onClick, label }) {
  const [hovered, setHovered] = useState(false);
  const buttonRef = useRef();
  return (
    <Interactive
      onHover={() => setHovered(true)}
      onBlur={() => setHovered(false)}
      onSelect={onClick}
    >
      <group position={position} ref={buttonRef}>
        <RoundedBox args={[0.65, 0.15, 0.05]} radius={0.05} smoothness={4}>
          <meshStandardMaterial color={hovered ? "white" : "green"} />
          <Text
            fontSize={0.05}
            position={[0, 0, 0.03]}
            color={hovered ? "black" : "white"}
            anchorX="center"
            anchorY="middle"
          >
            {label}
          </Text>
        </RoundedBox>
      </group>
    </Interactive>
  );
}

function VRScene({
  isRecording,
  setIsRecording,
  audioURL,
  setAudioURL,
  onSendAudio,
}) {
  const handleAudioRecorded = async (audioBlob) => {
    try {
      const response = await client.transcripts.create({
        audio_url: URL.createObjectURL(audioBlob),
      });
      console.log("Transcript:", response.text);
      alert("Transcript: " + response.text);
    } catch (error) {
      console.error("Error transcribing audio:", error);
    }
  };

  const handleSaveRecording = () => {
    if (audioURL) {
      fetch(audioURL)
        .then((response) => response.blob())
        .then((blob) => {
          const file = new File([blob], "recording.wav", { type: "audio/wav" });
          const a = document.createElement("a");
          const url = URL.createObjectURL(file);
          a.href = url;
          a.download = file.name;
          document.body.appendChild(a);
          a.click();
          setTimeout(() => {
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
          }, 0);
        })
        .catch((error) => {
          console.error("Error saving recording:", error);
        });
    }
  };

  return (
    <>
      <Suspense fallback={null}></Suspense>
      <Sky sunPosition={[0, 1, 0]} />
      <ambientLight intensity={0.5} />
      <pointLight position={[10, 10, 10]} />
      <Hands />
      <Controllers />
      <AudioCapture
        isRecording={isRecording}
        setAudioURL={setAudioURL}
        onAudioRecorded={handleAudioRecorded}
      />
      <group position={[0, 1.6, -2]}>
        <VRButtons
          position={[0, 0.3, 0]}
          onClick={() => setIsRecording(!isRecording)}
          label="Start Recording"
        />
        <VRButtons
          position={[0, 0.1, 0]}
          onClick={() => setIsRecording(false)}
          label="Stop Recording"
        />
        {/* {audioURL && (
          <>
            <VRButtons
              position={[0, -0.1, 0]}
              onClick={() => new Audio(audioURL).play()}
              label="Play Recording"
            />
            <VRButtons
              position={[0, -0.4, 0]}
              onClick={() => setAudioURL(null)}
              label="Delete Recording"
            />
            <VRButtons
              position={[0, -0.7, 0]}
              onClick={onSendAudio}
              label="Send Recording"
            />
            <VRButtons
              position={[0, -1, 0]}
              onClick={handleSaveRecording}
              label="Save Recording"
            />
          </>
        )} */}
        {audioURL && (
          <VRButtons
            position={[0, -0.1, 0]}
            onClick={() => new Audio(audioURL).play()}
            label="Play Recording"
          />
        )}
        {audioURL && (
          <VRButtons
            position={[0, -0.4, 0]}
            onClick={() => setAudioURL(null)}
            label=" Delete Recording"
          />
        )}
        <VRButtons
          position={[0, -0.7, 0]}
          onClick={onSendAudio}
          label="Send Recording"
        />
        <VRButtons
          position={[0, -1, 0]}
          onClick={handleSaveRecording}
          label="Save Recording"
        />
      </group>
    </>
  );
}

const DialogBox = ({ message, onClose }) => {
  const [hovered, setHovered] = useState(false);

  return (
    <group position={[-1, 1.6, -0.8]}>
      <RoundedBox args={[1, 0.5, 0.02]} radius={0.05} smoothness={4}>
        <meshStandardMaterial color="purple" />
      </RoundedBox>
      <Text
        fontSize={0.08}
        position={[0, 0.1, 0.03]}
        color="white"
        anchorX="center"
        anchorY="middle"
      >
        {message}
      </Text>
      <Interactive
        onSelect={onClose}
        onHover={() => setHovered(true)}
        onBlur={() => setHovered(false)}
      >
        <RoundedBox
          args={[0.8, 0.2, 0.02]}
          radius={0.05}
          smoothness={4}
          position={[0, -0.1, 0]}
        >
          <meshStandardMaterial color={hovered ? "white" : "green"} />
        </RoundedBox>
        <Text
          fontSize={0.04}
          position={[0, -0.1, 0.03]}
          color={hovered ? "black" : "white"}
          anchorX="center"
          anchorY="middle"
        >
          Close
        </Text>
      </Interactive>
    </group>
  );
};

export default function SpeechRecording() {
  const [isRecording, setIsRecording] = useState(false);
  const [audioURL, setAudioURL] = useState(null);
  const [responseText, setResponseText] = useState(null);

  const handleSendAudio = async () => {
    if (audioURL) {
      try {
        const response = await client.transcripts.create({
          audio_url: audioURL,
        });
        console.log("Transcript:", response.text);
        setResponseText(response.text);
      } catch (error) {
        console.error("Error transcribing audio:", error);
      }
    }
  };

  return (
    <>
      <VRScene
        isRecording={isRecording}
        setIsRecording={setIsRecording}
        audioURL={audioURL}
        setAudioURL={setAudioURL}
        onSendAudio={handleSendAudio}
      />
      {responseText && (
        <DialogBox
          message={responseText}
          onClose={() => setResponseText(null)}
        />
      )}
    </>
  );
}
