import React, { useState, useEffect } from "react";
import axios from "axios";
import { useFrame } from "@react-three/fiber";
import { Canvas, useLoader } from "@react-three/fiber";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import {
  Hands,
  useController,
  VRButton,
  XR,
  Interactive,
  Controllers,
} from "@react-three/xr";
import { Sky, Text, RoundedBox } from "@react-three/drei";
import { useNavigate } from "react-router-dom";

const ListData = () => {
  const [data, setData] = useState([]);
  const [scrollPosition, setScrollPosition] = useState(0);
  const itemHeight = 0.3; // Height of each item
  const visibleItems = 5; // Number of items visible at once
  const [hovered, setHovered] = useState(false);
  const [hoveredback, setHoveredback] = useState(false);
  const [hovereddown, setHovereddown] = useState(false);

  useEffect(() => {
    axios
      .get("https://randomuser.me/api/?results=10")
      .then((response) => {
        const usersArray = response.data.results;
        setData(usersArray);
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  const leftController = useController("left");
  const rightController = useController("right");

  useFrame(() => {
    if (leftController?.inputSource?.gamepad) {
      const { axes } = leftController.inputSource.gamepad;
      if (axes[3] < -0.5) handleScrollDown();
      if (axes[3] > 0.5) handleScrollDown();
    }
    if (rightController?.inputSource?.gamepad) {
      const { axes } = rightController.inputSource.gamepad;
      if (axes[3] < -0.5) handleScrollUp();
      if (axes[3] > 0.5) handleScrollDown();
    }
  });

  const handleScrollUp = () => {
    setScrollPosition((prev) => Math.max(prev - 1, 0));
  };

  const handleScrollDown = () => {
    setScrollPosition((prev) => Math.min(prev + 1, data.length - visibleItems));
  };

  const navigate = useNavigate();

  const handleBack = () => {
    navigate("/");
  };

  const Model = (props) => {
    const gltf = useLoader(GLTFLoader, "model/sunroof.glb");

    return <primitive object={gltf.scene} {...props} />;
  };

  return (
    <>
      <Sky sunPosition={[0, 1, 0]} />
      <ambientLight intensity={0.5} />
      <pointLight position={[10, 10, 10]} />
      <Hands />
      <Controllers />
      <Model position={[0, 2, -3]} />
      <group position={[0, 1, -2]}>
        <RoundedBox
          args={[2, 3, 0.05]}
          position={[0, 0, 0]}
          radius={0.05}
          smoothness={4}
        >
          <meshStandardMaterial color="gray" />
        </RoundedBox>
        <group position={[0, 1.2, 0]}>
          {data
            .slice(scrollPosition, scrollPosition + visibleItems)
            .map((user, index) => (
              <Text
                key={index}
                fontSize={0.1}
                position={[0, -index * itemHeight, 0.03]}
                color="black"
                anchorX="center"
                anchorY="middle"
              >
                {user.name.first} {user.name.last}
              </Text>
            ))}
        </group>
        {scrollPosition != 0 && (
          <Interactive
            onHover={() => setHovered(true)}
            onBlur={() => setHovered(false)}
            onSelectStart={handleScrollUp}
            onSelectEnd={handleScrollUp}
            onSelect={handleScrollUp}
          >
            <RoundedBox
              args={[0.5, 0.2, 0.05]}
              position={[-0.6, 1.5, 0.1]}
              radius={0.05}
              smoothness={4}
            >
              <meshStandardMaterial color={hovered ? "white" : "lightblue"} />
              <Text
                fontSize={0.1}
                position={[0, 0, 0.03]}
                color={"black"}
                anchorX="center"
                anchorY="middle"
              >
                Up
              </Text>
            </RoundedBox>
          </Interactive>
        )}

        <Interactive
          onHover={() => setHoveredback(true)}
          onBlur={() => setHoveredback(false)}
          onSelectStart={handleBack}
          onSelectEnd={handleBack}
          onSelect={handleBack}
        >
          <RoundedBox
            args={[0.5, 0.2, 0.05]}
            position={[0.6, 1.5, 0.1]}
            radius={0.05}
            smoothness={4}
          >
            <meshStandardMaterial color={hoveredback ? "white" : "lightblue"} />
            <Text
              fontSize={0.1}
              position={[0, 0, 0.03]}
              color={"black"}
              anchorX="center"
              anchorY="middle"
            >
              Back
            </Text>
          </RoundedBox>
        </Interactive>

        <Interactive
          onHover={() => setHovereddown(true)}
          onBlur={() => setHovereddown(false)}
          onSelectStart={handleScrollDown}
          onSelectEnd={handleScrollDown}
          onSelect={handleScrollDown}
        >
          <RoundedBox
            args={[0.5, 0.2, 0.05]}
            position={[-0.6, -1.5, 0.1]}
            radius={0.05}
            smoothness={4}
          >
            <meshStandardMaterial color={hovereddown ? "white" : "lightblue"} />
            <Text
              fontSize={0.1}
              position={[0, 0, 0.03]}
              color={"black"}
              anchorX="center"
              anchorY="middle"
            >
              Down
            </Text>
          </RoundedBox>
        </Interactive>
      </group>
    </>
  );
};

export default ListData;
