// navigation/Navigation.js
import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "../components/Home/Home";
import ListData from "../components/ListData/listData";
import { BrowserRouter as Router } from "react-router-dom";
import GraphChart from "../components/Graph/Graph";
import TestHome from "../components/Test/Test";
import AudioRecording from "../Audio/Record";
import SpeechRecording from "../Audio/SpeechToText";
import DemoAudioRecording from "../Audio/Demo";
import SpeechToText from "../SpeechRecogination/SpeechData";
import TexttoSpeech from "../TextToSpeech/TexttoSpeech";
import DemoTexttoSpeech from "../TextToSpeech/DemoText";
import PraticeScreen from "../components/Pratice/PraticeScreen";
import InterviewScreen from "../Interview/Interviewscreen";

const Navigation = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/list" element={<ListData />} />
        <Route path="/Graph" element={<GraphChart />} />
        <Route path="/PraticeScreen" element={<PraticeScreen />} />
        <Route path="/InterviewScreen" element={<InterviewScreen />} />
      </Routes>
    </Router>
  );
};

export default Navigation;
