// import React from "react";
// import ReactDOM from "react-dom/client";
// import "./index.css";
// import Home from "./components/Home";

// const App = () => {
//   return <Home />;
// };

// const root = ReactDOM.createRoot(document.getElementById("root"));
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );
// export default App;

import React from "react";
import { VRButton, XR } from "@react-three/xr";
import { Canvas } from "@react-three/fiber";
import Navigation from "./Navigation/Navigation";

const App = () => {
  return (
    <>
      <VRButton />
      <Canvas>
        <XR>
          <Navigation />
        </XR>
      </Canvas>
    </>
  );
};

export default App;
